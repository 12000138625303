.signup {

  &-header {
    text-align: center;
    background-image: url("../../assets/images/common/header/header_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 800;
    margin-bottom: 2rem;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 800;
      height: 180px;
      margin-bottom: 0;
    }
  }

  &-form {
    padding-left: 25%;
    padding-right: 25%;

    &-title {
      text-align: center;
      font-weight: 800;
      font-size: 30px;
      letter-spacing: 2px;

      h2 {
        color: #026eb8;
      }

      p {
        padding-left: 25%;
        padding-right: 25%;
        text-align: left;
        font-size: medium;
        font-weight: normal;
      }
    }

    &-corporation {
    }

    &-name {
      display: inline-block;
      width: calc(50% - .5rem);
      margin-bottom: .5rem;
    }

    &-email {
    }

    &-phone {
    }

    &-office {

      &-delete {
        cursor: pointer;
        position: relative;
        color: #999999;
        font-size: 24px;
        top: 4px;

        &:hover {
          color: #777777;
        }
      }
    }
  }

  &-content {
    text-align: center;
    height: 57.5vh;
  }
}

/* pc */
@media screen and (min-width: 992px) {
  .signup {
    &-header {
      margin-left: 15%;
      margin-right: 15%;

      h1 {
        font-size: 50px;
      }
    }

    &-form {
      &-title {
        font-size: 30px;
      }
    }
  }
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .signup {
    &-header {
      margin-left: 10%;
      margin-right: 10%;

      h1 {
        font-size: 50px;
      }
    }
    &-form {
      &-title {
        font-size: 30px;
      }
    }
  }
}

/* sp */
@media screen and (max-width: 767px) {
  .signup {
    &-header {
      h1 {
        font-size: 35px;
      }
    }

    &-form {
      padding-right: 0;
      padding-left: 0;

      &-title {
        font-size: 20px;
      }
    }
  }
}
