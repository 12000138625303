.base {
  &-header {
    background-color: #f0f2f5;
    position: fixed;
    z-index: 1;
    width: 100%;
    transition: all .5s ease-out;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.06);
    padding: 0;
  }

  &-content {
    margin-top: 64px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  &-footer {
    background-color: #222222;
  }
}