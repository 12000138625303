.movie {
  margin-bottom: 50px;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  width: 100%;

  .player {
    width: 100%;
    height: 100%;
  }
}
