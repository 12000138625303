.tokusyo {

  &-header {
    text-align: center;
    background-position: center;
    background-image: url("../../assets/images/common/header/header_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 800;
    margin-bottom: 2rem;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 800;
      height: 180px;
      margin-bottom: 0;
    }
  }

  &-body {
    padding: 0 25%;
  }
}

/* pc */
@media screen and (min-width: 992px) {
  .tokusyo {
    &-header {
      margin-left: 15%;
      margin-right: 15%;

      h1 {
        font-size: 50px;
      }
    }
  }
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .tokusyo {
    &-header {
      margin-left: 10%;
      margin-right: 10%;

      h1 {
        font-size: 50px;
      }
    }
  }
}

/* sp */
@media screen and (max-width: 767px) {
  .tokusyo {
    &-header {
      h1 {
        font-size: 35px;
      }
    }
  }
}
