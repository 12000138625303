@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@500;600&display=swap");

html {
  font-size: 62.5%
}

#defs {
  display: none
}

img {
  width: 100%
}

ul li {
  list-style: none;
  margin: 0
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

svg {
  display: block
}

h1 {
  margin: 0
}

h2 {
  margin: 0 auto
}

p {
  margin: 0
}

.disp-block-pc {
  display: none
}

@media all and (min-width: 800px) {
  .disp-block-pc {
    display: block
  }
}

.disp-block-sptb {
  display: block
}

@media all and (min-width: 800px) {
  .disp-block-sptb {
    display: none
  }
}

.disp-block-pctb {
  display: none
}

@media all and (min-width: 480px) {
  .disp-block-pctb {
    display: block
  }
}

.disp-block-sp {
  display: block
}

@media all and (min-width: 480px) {
  .disp-block-sp {
    display: none
  }
}

.disp-block-pcsp {
  display: none
}

@media all and (min-width: 375px) {
  .disp-block-pcsp {
    display: block
  }
}

.disp-block-sponly {
  display: block
}

@media all and (min-width: 375px) {
  .disp-block-sponly {
    display: none
  }
}

.disp-inline-pc {
  display: none
}

@media all and (min-width: 800px) {
  .disp-inline-pc {
    display: inline
  }
}

.disp-inline-sptb {
  display: inline
}

@media all and (min-width: 800px) {
  .disp-inline-sptb {
    display: none
  }
}

.disp-inline-pctb {
  display: none
}

@media all and (min-width: 480px) {
  .disp-inline-pctb {
    display: inline
  }
}

.disp-inline-sp {
  display: inline
}

@media all and (min-width: 480px) {
  .disp-inline-sp {
    display: none
  }
}

.disp-inline-pcsp {
  display: none
}

@media all and (min-width: 375px) {
  .disp-inline-pcsp {
    display: inline
  }
}

.disp-inline-sponly {
  display: inline
}

@media all and (min-width: 375px) {
  .disp-inline-sponly {
    display: none
  }
}

@media all and (min-width: 375px) {
  .break.-minsp:after {
    content: "\a";
    white-space: pre
  }
}

@media all and (min-width: 480px) {
  .break.-mintb:after {
    content: "\a";
    white-space: pre
  }
}

@media all and (min-width: 500px) {
  .break.-minch:after {
    content: "\a";
    white-space: pre
  }
}

@media all and (min-width: 800px) {
  .break.-minpc:after {
    content: "\a";
    white-space: pre
  }
}

@media all and (max-width: calc(800px - 1px)) {
  .break.-maxpc {
    content: "\a";
    white-space: pre
  }
}

@media all and (max-width: calc(480px - 1px)) {
  .break.-maxtb {
    content: "\a";
    white-space: pre
  }
}

@media all and (max-width: calc(375px - 1px)) {
  .break.-maxsp {
    content: "\a";
    white-space: pre
  }
}

.align-l {
  text-align: left !important
}

@media all and (min-width: 800px) {
  .align-l {
    text-align: left !important
  }
}

.align-r {
  text-align: left !important
}

@media all and (min-width: 800px) {
  .align-r {
    text-align: right !important
  }
}

.align-c {
  text-align: left !important
}

@media all and (min-width: 800px) {
  .align-c {
    text-align: center !important
  }
}

.br {
  display: none
}

@media all and (min-width: 800px) {
  .br {
    display: inline-block
  }
}

.hover, .p0-btn1_inner, .p0-btn1 a, .p0-btn2_inner, .p0-btn2 a {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out
}

.hover:hover, .hover:focus, .p0-btn1_inner:hover, .p0-btn1 a:hover, .p0-btn2_inner:hover, .p0-btn2 a:hover, .p0-btn1_inner:focus, .p0-btn1 a:focus, .p0-btn2_inner:focus, .p0-btn2 a:focus {
  filter: alpha(opacity=70);
  opacity: 0.7
}

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block
}

@media all and (min-width: 800px) {
  .anchor {
    top: -72px
  }
}

@media all and (min-width: 1120px) {
  .anchor {
    top: -80px
  }
}

body {
  color: #444;
  background: #f5f5f5;
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400
}

@media all and (min-width: 800px) {
  body {
    font-size: 1.5rem
  }
}

a {
  color: rgba(68, 68, 68, 0.8);
  text-decoration: none
}

::-moz-selection, ::-moz-selection {
  background: color-effect02(1)
}

::selection, ::-moz-selection {
  background: color-effect02(1)
}

.js-anchor {
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px)
}

.l-header {
  display: block;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent
}

@media all and (min-width: 800px) {
  .l-header:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    content: "";
    background-color: #0360a5
  }
}

@media all and (min-width: 800px) {
  .l-header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    background-color: #0d6fb8
  }
}

.l-header.js-isscroll {
  display: block
}

@media all and (min-width: 800px) {
  .l-header.js-isscroll {
    -webkit-animation: 0.3s fadeIn;
    animation: 0.3s fadeIn
  }
}

.l-header.js-noscroll {
  display: block
}

@media all and (min-width: 800px) {
  .l-header.js-noscroll {
    -webkit-animation: 0.3s fadeOut;
    animation: 0.3s fadeOut;
    display: none
  }
}

.l-header_inner {
  height: 100%
}

.l-wrap {
  overflow: hidden
}

.l-section {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  box-sizing: border-box
}

.l-section.-visible {
  overflow: visible
}

.l-section.-pt1530 {
  padding-top: 15px
}

@media all and (min-width: 800px) {
  .l-section.-pt1530 {
    padding-top: 30px
  }
}

.l-section.-pt2070 {
  padding-top: 20px
}

@media all and (min-width: 800px) {
  .l-section.-pt2070 {
    padding-top: 70px
  }
}

.l-section.-pt3570 {
  padding-top: 35px
}

@media all and (min-width: 800px) {
  .l-section.-pt3570 {
    padding-top: 70px
  }
}

.l-section.-pt50100 {
  padding-top: 50px
}

@media all and (min-width: 800px) {
  .l-section.-pt50100 {
    padding-top: 100px
  }
}

.l-section.-pb2040 {
  padding-bottom: 20px
}

@media all and (min-width: 800px) {
  .l-section.-pb2040 {
    padding-bottom: 40px
  }
}

.l-section.-pb2550 {
  padding-bottom: 25px
}

@media all and (min-width: 800px) {
  .l-section.-pb2550 {
    padding-bottom: 50px
  }
}

.l-section.-pb3060 {
  padding-bottom: 30px
}

@media all and (min-width: 800px) {
  .l-section.-pb3060 {
    padding-bottom: 60px
  }
}

.l-section.-pb60120 {
  padding-bottom: 60px
}

@media all and (min-width: 800px) {
  .l-section.-pb60120 {
    padding-bottom: 120px
  }
}

.l-section.-largeSpace {
  padding: 40px 0
}

@media all and (min-width: 800px) {
  .l-section.-largeSpace {
    padding: 80px 0
  }
}

.l-section.-bg01 {
  background-color: #d8eeff
}

.l-section_content {
  position: relative;
  overflow: hidden;
  width: 90%;
  margin: 0 auto
}

@media all and (min-width: 1240px) {
  .l-section_content {
    width: 1120px
  }
}

.l-section_content.-wide {
  position: relative;
  width: 90%;
  margin: 0 auto
}

@media all and (min-width: 1240px) {
  .l-section_content.-wide {
    width: calc(100% - 60px - 60px)
  }
}

.l-section_content.-visible {
  overflow: visible
}

.l-section_content_inner {
  width: 100%;
  margin: 0 auto;
  position: relative
}

@media all and (min-width: 800px) {
  .l-section_content_inner {
    width: 90%
  }
}

@media all and (min-width: 1120px) {
  .l-section_content_inner {
    width: 1000px
  }
}

.l-footer {
  background-color: #bfc1c2;
  padding: 15px 0 85px 0
}

@media all and (min-width: 620px) {
  .l-footer {
    padding: 30px 0 110px 0
  }
}

.l-footer_inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center
}

@media all and (min-width: 620px) {
  .l-footer_inner {
    flex-direction: row;
    align-items: flex-end
  }
}

.p0-btn1 {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 205px;
  clear: both;
  text-align: center;
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: #fff
}

.p0-btn1_inner, .p0-btn1 a {
  display: block;
  width: 100%;
  padding: 11px 0 12px;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 1
}

.p0-btn1_inner, .p0-btn1 a {
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff;
  background-color: #425360
}

.p0-btn1_inner:after, .p0-btn1 a:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 12px;
  height: 10px;
  content: "";
  background-image: url("../images/common/icon_gt2_wh.png");
  background-position: center center;
  background-size: 12px auto;
  background-repeat: no-repeat
}

.p0-btn1_inner:hover, .p0-btn1 a:hover {
  opacity: 1;
  color: #001b2f;
  background-color: #ff9f00
}

.p0-btn1_inner a {
  padding: 0
}

.p0-btn1 p {
  margin-bottom: 0 !important
}

.p0-btn2 {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 340px;
  clear: both;
  text-align: center;
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: #fff
}

.p0-btn2_inner, .p0-btn2 a {
  display: block;
  width: 100%;
  padding: 11px 0 12px;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 1
}

.p0-btn2_inner, .p0-btn2 a {
  position: relative;
  padding: 13px 0 14px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  color: #001b2f;
  background-color: #f39800
}

.p0-btn2_inner:after, .p0-btn2 a:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 40px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 20px;
  height: 16px;
  content: "";
  background-image: url("../images/common/icon_gt2_wh.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat
}

.p0-btn2_inner a {
  padding: 0
}

.p0-btn2 p {
  margin-bottom: 0 !important
}

.p0-flexbtns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch
}

@media all and (min-width: 800px) {
  .p0-flexbtns {
    flex-wrap: nowrap;
    flex-direction: row
  }
}

.p0-flexbtns.-left {
  justify-content: flex-start
}

.p0-flexbtns.-center {
  justify-content: center;
  align-items: center
}

@media all and (min-width: 800px) {
  .p0-flexbtns.-center {
    align-items: stretch
  }
}

.p0-flexbtns.-right {
  justify-content: flex-end
}

.p0-flexbtns_item {
  margin-bottom: 20px
}

@media all and (min-width: 800px) {
  .p0-flexbtns_item {
    margin-bottom: 0;
    margin-right: 2vw
  }
}

@media all and (min-width: 1120px) {
  .p0-flexbtns_item {
    margin-right: 20px
  }
}

.p0-flexbtns_item:last-of-type {
  margin-bottom: 0
}

@media all and (min-width: 800px) {
  .p0-flexbtns_item:last-of-type {
    margin-right: 0
  }
}

@media all and (min-width: 1120px) {
  .p0-flexbtns_item:last-of-type {
    margin-right: 0
  }
}

.p0-list.-check {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.p0-list.-check li {
  margin: 0;
  padding-left: 15px;
  box-sizing: border-box;
  background-image: url("../images/common/icon_check.png");
  background-position: left top 6px;
  background-size: 10px auto;
  background-repeat: no-repeat
}

.p0-list.-normal {
  margin: 0 0 0 -0.33em;
  padding: 0;
  list-style-type: disc
}

.p0-list.-normal li {
  padding-left: 1em;
  text-indent: -1em;
  box-sizing: border-box;
  line-height: 2
}

.p0-list.-normal li:before {
  content: "\030FB"
}

.p0-border {
  position: relative;
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px);
  border-style: none;
  background-color: #0d6fb8;
  width: calc(100% + 7px + 7px);
  height: 1px
}

@media all and (min-width: 800px) {
  .p0-border {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    width: calc(100% + 15px + 15px)
  }
}

.p0-border.-endsdot:before, .p0-border.-endsdot:after {
  position: absolute;
  top: 50%;
  width: 7px;
  height: 7px;
  content: "";
  background-color: #0d6fb8;
  border-radius: 50%;
  box-sizing: border-box
}

.p0-border.-endsdot:before {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.p0-border.-endsdot:after {
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%)
}

.p0-hero {
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("../images/front/kv/bg_sp.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto
}

@media all and (min-width: 800px) {
  .p0-hero {
    padding: 0;
    background-image: url("../images/front/kv/bg_pc.jpg");
    background-position: left 45% top;
    background-size: auto 100%
  }
}

@media all and (min-width: 860px) {
  .p0-hero {
    background-position: center top
  }
}

.p0-hero_catchcopy {
  position: relative;
  z-index: 20;
  width: 75.0666666666667vw;
  margin-bottom: 138px
}

@media all and (min-width: 375px) {
  .p0-hero_catchcopy {
    width: 58.0769230769231vw
  }
}

@media all and (min-width: 520px) {
  .p0-hero_catchcopy {
    width: 46.875vw;
    margin-bottom: 95px
  }
}

@media all and (min-width: 700px) {
  .p0-hero_catchcopy {
    margin-bottom: 20px
  }
}

@media all and (min-width: 800px) {
  .p0-hero_catchcopy {
    width: 403px;
    margin-bottom: 20px
  }
}

.p0-hero_catchcopy img {
  -webkit-transform: translate(-1.21655%, -3.14465%);
  transform: translate(-1.21655%, -3.14465%)
}

.p0-hero_mid {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  z-index: 10
}

.p0-hero_mid_people {
  position: relative;
  z-index: 15
}

.p0-hero_mid_recommendation {
  position: relative;
  z-index: 11;
  width: 252px
}

@media all and (min-width: 800px) {
  .p0-hero_mid_recommendation {
    width: 252px;
    margin-left: -15px
  }
}

.p0-hero_mid_recommendation img {
  -webkit-transform: translate(-6.75944%, 8.95141%);
  transform: translate(-6.75944%, 8.95141%)
}

.p0-hero_mid_recommendation.-sp {
  display: block;
  position: absolute;
  top: -22px;
  left: -5vw;
  width: 50%
}

@media all and (min-width: 375px) {
  .p0-hero_mid_recommendation.-sp {
    top: -60px;
    left: -60px;
    width: 58%
  }
}

@media all and (min-width: 520px) {
  .p0-hero_mid_recommendation.-sp {
    top: 0.625vw;
    left: -155px;
    width: 58%
  }
}

@media all and (min-width: 700px) {
  .p0-hero_mid_recommendation.-sp {
    top: -1.25vw;
    left: -1vw
  }
}

@media all and (min-width: 800px) {
  .p0-hero_mid_recommendation.-sp {
    display: none
  }
}

.p0-hero_mid_recommendation.-pc {
  display: none
}

@media all and (min-width: 800px) {
  .p0-hero_mid_recommendation.-pc {
    display: block
  }
}

.p0-hero_mid_ex {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 0;
  width: 277px;
  margin-top: 0;
  margin-left: 2.5vw
}

@media all and (min-width: 700px) {
  .p0-hero_mid_ex {
    width: 40.5vw;
    margin-top: -16vw;
    margin-left: 2.5vw
  }
}

@media all and (min-width: 800px) {
  .p0-hero_mid_ex {
    position: relative;
    right: auto;
    left: 0;
    width: 530px;
    margin-top: 0;
    margin-left: -20px
  }
}

.p0-hero_mid_ex_img.-sp {
  display: block;
  z-index: 2
}

@media all and (min-width: 700px) {
  .p0-hero_mid_ex_img.-sp {
    display: none
  }
}

.p0-hero_mid_ex_img.-kvs {
  display: none
}

@media all and (min-width: 700px) {
  .p0-hero_mid_ex_img.-kvs {
    display: block
  }
}

@media all and (min-width: 800px) {
  .p0-hero_mid_ex_img.-kvs {
    display: none
  }
}

.p0-hero_mid_ex_img.-pc {
  display: none
}

@media all and (min-width: 800px) {
  .p0-hero_mid_ex_img.-pc {
    display: block
  }
}

.p0-hero_btm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  margin-top: -15px;
  padding: 30px 0 15px;
  box-sizing: border-box
}

.p0-hero_btm_obi {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100vw;
  max-width: 1620px;
  height: 100%;
  background-image: url("../images/front/kv/obi.png");
  background-position: center top;
  background-repeat: repeat-y;
  background-size: 100% auto
}

.p0-hero_btm_box1 {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 15px
}

@media all and (min-width: 480px) {
  .p0-hero_btm_box1 {
    margin-bottom: 20px
  }
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box1 {
    width: 43%;
    margin-bottom: 0
  }
}

.p0-hero_btm_box2 {
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box2 {
    width: 57%;
    margin-bottom: 10px;
    padding-left: 5px
  }
}

.p0-hero_btm_box2_txt {
  margin-bottom: 10px
}

@media all and (min-width: 480px) {
  .p0-hero_btm_box2_txt {
    margin-bottom: 15px
  }
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box2_txt {
    margin-bottom: 10px
  }
}

.p0-hero_btm_box2_txt_line1 {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% + 1.875vw + 1.875vw);
  margin-bottom: 5px
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box2_txt_line1 {
    width: 100%
  }
}

.p0-hero_btm_box2_circle {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% + 10px + 10px)
}

@media all and (min-width: 480px) {
  .p0-hero_btm_box2_circle {
    width: calc(100% + 10px + 10px + 15px + 15px)
  }
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box2_circle {
    width: calc(100% + 10px + 10px)
  }
}

.p0-hero_btm_box2_circle img {
  width: 50%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box
}

@media all and (min-width: 480px) {
  .p0-hero_btm_box2_circle img {
    width: 33.33333%;
    width: calc(100% / 3);
    padding: 0 10px
  }
}

@media all and (min-width: 800px) {
  .p0-hero_btm_box2_circle img {
    padding: 0 10px
  }
}

.p0-hero_sup {
  position: relative;
  z-index: 1;
  margin-bottom: 10px
}

@media all and (min-width: 480px) {
  .p0-hero_sup {
    margin-bottom: 15px
  }
}

@media all and (min-width: 800px) {
  .p0-hero_sup {
    margin-bottom: 0
  }
}

.p0-people {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: auto;
  height: 150px;
  margin-left: -1.25vw
}

@media all and (min-width: 375px) {
  .p0-people {
    height: 167px;
    margin-left: -2.5vw
  }
}

@media all and (min-width: 700px) {
  .p0-people {
    margin-left: 0
  }
}

@media all and (min-width: 800px) {
  .p0-people {
    height: 11.071428571428571vw;
    margin-left: -1.339285714285714vw
  }
}

@media all and (min-width: 1120px) {
  .p0-people {
    height: 124px;
    margin-left: -15px
  }
}

.p0-people_item {
  height: 50%
}

.p0-people_item img {
  width: auto;
  height: 100%
}

.p0-people_item:nth-of-type(1) {
  margin-right: 0
}

@media all and (min-width: 375px) {
  .p0-people_item:nth-of-type(1) {
    margin-right: 0.875vw
  }
}

@media all and (min-width: 375px) {
  .p0-people_item:nth-of-type(1) {
    height: 100%;
    margin-right: -0.875vw
  }
}

@media all and (min-width: 440px) {
  .p0-people_item:nth-of-type(1) {
    margin-right: 0.875vw
  }
}

@media all and (min-width: 800px) {
  .p0-people_item:nth-of-type(1) {
    margin-right: 5px
  }
}

.p0-people_item:nth-of-type(2) {
  margin-right: 0
}

@media all and (min-width: 375px) {
  .p0-people_item:nth-of-type(2) {
    margin-right: 0.875vw
  }
}

@media all and (min-width: 440px) {
  .p0-people_item:nth-of-type(2) {
    height: 100%
  }
}

@media all and (min-width: 800px) {
  .p0-people_item:nth-of-type(2) {
    margin-right: 5px
  }
}

.p0-people_item:nth-of-type(3) {
  margin-right: 0
}

@media all and (min-width: 375px) {
  .p0-people_item:nth-of-type(3) {
    margin-right: 1.5vw
  }
}

@media all and (min-width: 520px) {
  .p0-people_item:nth-of-type(3) {
    height: 100%
  }
}

@media all and (min-width: 800px) {
  .p0-people_item:nth-of-type(3) {
    margin-right: 10px
  }
}

.p0-people_item:nth-of-type(4) {
  margin-right: 0
}

@media all and (min-width: 375px) {
  .p0-people_item:nth-of-type(4) {
    margin-right: 1.25vw
  }
}

@media all and (min-width: 600px) {
  .p0-people_item:nth-of-type(4) {
    height: 100%
  }
}

@media all and (min-width: 800px) {
  .p0-people_item:nth-of-type(4) {
    margin-right: 8px
  }
}

@media all and (min-width: 700px) {
  .p0-people_item:nth-of-type(5) {
    height: 100%
  }
}

.p0-txtimgbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 40px
}

@media all and (min-width: 800px) {
  .p0-txtimgbox {
    margin-bottom: 80px
  }
}

.p0-txtimgbox:last-of-type {
  margin-bottom: 0
}

@media all and (min-width: 800px) {
  .p0-txtimgbox:last-of-type {
    margin-bottom: 80px
  }
}

@media all and (min-width: 800px) {
  .p0-txtimgbox.-rev {
    flex-direction: row-reverse
  }
}

@media all and (min-width: 1000px) {
  .p0-txtimgbox.-rev .p0-txtimgbox_item.-txt {
    left: auto;
    right: 5%
  }
}

.p0-txtimgbox_item.-txt {
  position: relative;
  width: 100%;
  z-index: 1
}

.p0-txtimgbox_item.-txt:after {
  display: block;
  width: 100%;
  height: 10.625vw;
  content: "";
  background-image: url("../images/front/function/bg_btmtri.png");
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat
}

@media all and (min-width: 1000px) {
  .p0-txtimgbox_item.-txt:after {
    display: none
  }
}

@media all and (min-width: 1000px) {
  .p0-txtimgbox_item.-txt {
    left: 5%;
    z-index: 3;
    width: 45%
  }
}

.p0-txtimgbox_item.-img {
  z-index: 3;
  width: 100%;
  margin-top: -1.875vw;
  text-align: center
}

@media all and (min-width: 1000px) {
  .p0-txtimgbox_item.-img {
    z-index: 1;
    width: 55%;
    margin-top: 0
  }
}

.p0-txtimgbox_item.-img img {
  width: 100%;
  max-width: 550px
}

@media all and (min-width: 375px) {
  .p0-txtimgbox_item.-img img {
    width: 90%
  }
}

@media all and (min-width: 480px) {
  .p0-txtimgbox_item.-img img {
    width: 86%
  }
}

@media all and (min-width: 620px) {
  .p0-txtimgbox_item.-img img {
    width: 82%
  }
}

@media all and (min-width: 800px) {
  .p0-txtimgbox_item.-img img {
    width: 76%
  }
}

@media all and (min-width: 1000px) {
  .p0-txtimgbox_item.-img img {
    width: 100%
  }
}

.p0-txtimgbox_item_inner {
  padding: 20px 20px 20px;
  box-sizing: border-box;
  background-color: rgba(245, 245, 245, 0.8)
}

@media all and (min-width: 800px) {
  .p0-txtimgbox_item_inner {
    padding: 40px 30px 50px
  }
}

.p0-txtimgbox_item_icon {
  height: 63px;
  margin-bottom: 15px;
  text-align: center
}

.p0-txtimgbox_item_icon img {
  width: auto;
  height: 100%
}

.p0-txtimgbox_item_ttl {
  margin-bottom: 10px;
  font-family: 'Shippori Mincho', serif;
  font-weight: 500;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.55;
  text-align: center
}

@media all and (min-width: 800px) {
  .p0-txtimgbox_item_ttl {
    font-size: 2.8rem
  }
}

@media all and (min-width: 800px) {
  .p0-txtimgbox_item_ttl {
    margin-bottom: 35px
  }
}

.p0-featurebox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch
}

.p0-featurebox .featurebox {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 15px 25px;
  border: 2px solid #0d6fb8;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8)
}

@media all and (min-width: 800px) {
  .p0-featurebox .featurebox {
    width: 48%;
    margin-bottom: 3vw;
    padding: 20px 30px 30px;
    border-width: 3px
  }
}

@media all and (min-width: 1000px) {
  .p0-featurebox .featurebox {
    margin-bottom: 40px;
    padding: 25px 40px 40px
  }
}

.p0-featurebox .featurebox .img {
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
  text-align: center
}

@media all and (min-width: 800px) {
  .p0-featurebox .featurebox .img {
    margin-bottom: 15px
  }
}

.p0-featurebox .featurebox .img img {
  width: 100%;
  height: auto
}

.p0-featurebox .featurebox .ttl {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: #0d6fb8;
  text-align: center;
  line-height: 1.4
}

@media all and (min-width: 800px) {
  .p0-featurebox .featurebox .ttl {
    font-size: 2.2rem
  }
}

@media all and (min-width: 1000px) {
  .p0-featurebox .featurebox .ttl {
    font-size: 2.7rem
  }
}

@media all and (min-width: 1120px) {
  .p0-featurebox .featurebox .ttl {
    font-size: 3.2rem
  }
}

.p0-featurebox .featurebox:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 32px 32px 0;
  border-color: transparent #0d6fb8 transparent transparent
}

@media all and (min-width: 800px) {
  .p0-featurebox .featurebox:before {
    border-width: 0 40px 40px 0
  }
}

.p0-mainsysbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  left: 0;
  padding-bottom: 35px;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-mainsysbox {
    left: -5%;
    padding-bottom: 60px
  }
}

.p0-mainsysbox_imgbox {
  width: 100%;
  text-align: center
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_imgbox {
    width: 38.4%
  }
}

.p0-mainsysbox_imgbox img {
  position: relative;
  z-index: 1;
  width: 338px;
  max-width: 100%;
  height: auto
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_imgbox img {
    width: 100%
  }
}

.p0-mainsysbox_imgbox:before {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 0;
  width: 100vw;
  height: 74.6vw;
  content: "";
  background-color: #fff
}

@media all and (min-width: 375px) {
  .p0-mainsysbox_imgbox:before {
    height: 280px
  }
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_imgbox:before {
    display: none
  }
}

.p0-mainsysbox_txtbox {
  width: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  color: #fff
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_txtbox {
    width: 54%;
    padding-top: 40px
  }
}

@media all and (min-width: 1000px) {
  .p0-mainsysbox_txtbox {
    padding-top: 80px
  }
}

.p0-mainsysbox_txtbox_ttl {
  margin-bottom: 10px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  text-align: justify;
  line-height: 1.59
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_txtbox_ttl {
    margin-bottom: 45px;
    font-size: 2.56rem
  }
}

@media all and (min-width: 1000px) {
  .p0-mainsysbox_txtbox_ttl {
    font-size: 3.2rem
  }
}

.p0-mainsysbox_txtbox_txt {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: justify;
  line-height: 2
}

@media all and (min-width: 800px) {
  .p0-mainsysbox_txtbox_txt {
    font-size: 1.8rem
  }
}

.p0-pricebox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch
}

.p0-pricebox_item {
  width: 100%;
  border: 1px solid rgba(212, 215, 216, 0.9);
  border-bottom-style: none;
  box-sizing: border-box
}

@media all and (min-width: 480px) {
  .p0-pricebox_item {
    width: 50%;
    border-right-style: none;
    border-bottom-style: none
  }
}

@media all and (min-width: 1000px) {
  .p0-pricebox_item {
    width: 25%;
    border-right-style: none;
    border-bottom-style: solid
  }
}

.p0-pricebox_item:nth-of-type(2n+1) {
  background-color: #edfbff
}

.p0-pricebox_item:nth-of-type(2n) {
  background-color: #fdfdfd
}

.p0-pricebox_item:nth-of-type(2n) .p0-pricebox_item_mid {
  background-color: #d4f1fb
}

@media all and (min-width: 480px) {
  .p0-pricebox_item:nth-of-type(2n) {
    border-right-style: solid
  }
}

@media all and (min-width: 1000px) {
  .p0-pricebox_item:nth-of-type(2n) {
    border-right-style: none
  }
}

@media all and (min-width: 480px) {
  .p0-pricebox_item:nth-of-type(3) {
    border-bottom-style: solid;
    background-color: #fdfdfd
  }

  .p0-pricebox_item:nth-of-type(3) .p0-pricebox_item_mid {
    background-color: #d4f1fb
  }
}

@media all and (min-width: 1000px) {
  .p0-pricebox_item:nth-of-type(3) {
    background-color: #edfbff
  }

  .p0-pricebox_item:nth-of-type(3) .p0-pricebox_item_mid {
    background-color: #a4e6fc
  }
}

.p0-pricebox_item:nth-of-type(4) {
  border-bottom-style: solid
}

@media all and (min-width: 480px) {
  .p0-pricebox_item:nth-of-type(4) {
    background-color: #edfbff
  }

  .p0-pricebox_item:nth-of-type(4) .p0-pricebox_item_mid {
    background-color: #a4e6fc
  }
}

@media all and (min-width: 1000px) {
  .p0-pricebox_item:nth-of-type(4) {
    border-right-style: solid;
    background-color: #fdfdfd
  }

  .p0-pricebox_item:nth-of-type(4) .p0-pricebox_item_mid {
    background-color: #d4f1fb
  }
}

.p0-pricebox_item:hover {
  background-color: #d8eeff
}

.p0-pricebox_item:hover .p0-pricebox_item_plan .-name {
  color: #0d6fb8
}

.p0-pricebox_item:hover .p0-pricebox_item_mid {
  color: #fff;
  background-color: #0d6fb8
}

.p0-pricebox_item:hover .p0-pricebox_item_users {
  color: #fff
}

.p0-pricebox_item_top, .p0-pricebox_item_mid, .p0-pricebox_item_btm {
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box
}

.p0-pricebox_item_top {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center
}

.p0-pricebox_item_mid {
  padding-top: 12px;
  padding-bottom: 14px;
  text-align: center;
  background-color: #a4e6fc
}

.p0-pricebox_item_btm {
  padding-top: 20px;
  padding-bottom: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: justify;
  line-height: 1.6
}

@media all and (min-width: 800px) {
  .p0-pricebox_item_btm {
    padding-bottom: 50px
  }
}

.p0-pricebox_item_plan {
  margin-bottom: 12px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  color: #001b2f
}

@media all and (min-width: 800px) {
  .p0-pricebox_item_plan {
    margin-bottom: 8px
  }
}

.p0-pricebox_item_price {
  margin-bottom: 15px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  color: #0d6fb8
}

.p0-pricebox_item_sup {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem
}

@media all and (min-width: 800px) {
  .p0-pricebox_item_sup {
    font-size: 1.3rem
  }
}

.p0-pricebox_item_users {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  color: #001b2f
}

.p0-slideshow_item {
  height: auto !important
}

.swiper-container-case {
  position: relative
}

.swiper-button-prev, .swiper-button-next {
  margin: 0;
  background-position: center center;
  background-size: 30px auto;
  background-repeat: no-repeat
}

.slick-prev {
  left: -2.678571428571429vw !important;
  right: auto !important;
  -webkit-transform: translate(-100%, -50%) !important;
  transform: translate(-100%, -50%) !important;
  background-image: url("../images/common/icon_prev.png") !important;
  background-size: 30px auto !important;
  background-repeat: no-repeat !important;
  width: 30px !important;
  height: 44px !important;
}

@media all and (min-width: 1120px) {
  .swiper-container-case .swiper-button-prev {
    left: -20px
  }
}

@media all and (min-width: 1240px) {
  .swiper-container-case .swiper-button-prev {
    left: -30px
  }
}

.slick-next {
  left: auto !important;
  right: -2.678571428571429vw !important;
  -webkit-transform: translate(100%, -50%) !important;
  transform: translate(100%, -50%) !important;
  background-size: 30px auto !important;
  width: 30px !important;
  height: 44px !important;
  background-image: url("../images/common/icon_next.png") !important;
  background-repeat: no-repeat !important;
}

.slick-next:before, .slick-prev:before {
  content: none !important;
}

@media all and (min-width: 1120px) {
  .slick-next {
    right: -20px !important;
  }
}

@media all and (min-width: 1240px) {
  .swiper-container-case .swiper-button-next {
    right: -30px
  }
}

.swiper-container-case .swiper-case-pagination.swiper-pagination-bullets {
  bottom: -30px
}

.swiper-container-case .swiper-case-pagination .swiper-pagination-bullet {
  background-color: rgba(71, 201, 244, 0.25);
  opacity: 1
}

.swiper-container-case .swiper-case-pagination .swiper-pagination-bullet-active {
  background-color: #47c9f4
}

.p0-case {
  background-color: #f5f5f5;
  padding: 20px 20px 5px;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-case {
    padding: 30px 40px 50px
  }
}

.p0-case.-pc {
  height: 100%
}

.p0-case.-sp {
  margin-bottom: 20px
}

.p0-case_img {
  width: 100%
}

.p0-case_img img {
  width: 100%;
  height: auto
}

.p0-case_txt img {
  float: left;
  width: 47.5%;
  margin: 8px 40px 40px 0
}

.p0-envtbl_head {
  width: 100%;
  border-bottom: 4px solid #fff;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #001b2f
}

.p0-envtbl_head.-terminal {
  padding: 10px 18px;
  font-size: 1.6rem;
  background-color: #d2e4ea;
  text-align: center
}

@media all and (min-width: 800px) {
  .p0-envtbl_head.-terminal {
    font-size: 2.6rem
  }
}

@media all and (min-width: 800px) {
  .p0-envtbl_head.-terminal {
    padding: 12px 40px
  }
}

.p0-envtbl_head.-os {
  padding: 8px 18px;
  font-size: 1.4rem;
  background-color: #d4d7d8
}

@media all and (min-width: 800px) {
  .p0-envtbl_head.-os {
    font-size: 1.6rem
  }
}

@media all and (min-width: 800px) {
  .p0-envtbl_head.-os {
    padding: 12px 40px
  }
}

.p0-envtbl_body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch
}

.p0-envtbl_body_item {
  width: 100%;
  margin-bottom: 5px;
  background-color: #f5f5f5
}

.p0-envtbl_body_item:last-of-type {
  margin-bottom: 0
}

@media all and (min-width: 620px) {
  .p0-envtbl_body_item {
    width: calc(50% - 2px);
    margin-bottom: 0
  }
}

.p0-envtbl_body_txt {
  padding: 10px 18px;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-envtbl_body_txt {
    padding: 10px 40px
  }
}

.p0-accordion {
  display: block
}

.p0-accordion_head {
  position: relative;
  box-sizing: border-box
}

.p0-accordion.-type1 {
  display: flex;
  flex-direction: column
}

.p0-accordion.-type1 .p0-accordion_head {
  display: block;
  overflow: hidden;
  padding: 10px 15px;
  border-bottom: 6px solid #0d6fb8;
  cursor: pointer
}

@media all and (min-width: 800px) {
  .p0-accordion.-type1 .p0-accordion_head {
    display: none
  }
}

.p0-accordion.-type1 .p0-accordion_head_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center
}

.p0-accordion.-type1 .p0-accordion_head_ttl .-close {
  display: inline
}

.p0-accordion.-type1 .p0-accordion_head_ttl .-open {
  display: none
}

.p0-accordion.-type1 .p0-accordion_head_ttl:after {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 11px;
  content: "";
  margin-left: 10px;
  background-image: url("../images/common/icon_gt2_bk.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat
}

.p0-accordion.-type1 .p0-accordion_head.is-active .p0-accordion_head_ttl:after {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1)
}

.p0-accordion.-type1 .p0-accordion_head.is-active .-close {
  display: none
}

.p0-accordion.-type1 .p0-accordion_head.is-active .-open {
  display: inline
}

.p0-accordion.-type1 .p0-accordion_body {
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 6px solid #0d6fb8;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-accordion.-type1 .p0-accordion_body {
    border-bottom-style: none;
    padding-bottom: 0
  }
}

.p0-accordion.-type1 .p0-accordion_body.-close {
  display: none
}

@media all and (min-width: 800px) {
  .p0-accordion.-type1 .p0-accordion_body.-close {
    display: block !important
  }
}

.p0-accordion.-type2 {
  display: flex;
  flex-direction: column-reverse
}

.p0-accordion.-type2 .p0-accordion_head {
  display: block;
  overflow: hidden;
  padding: 10px 15px;
  cursor: pointer
}

@media all and (min-width: 800px) {
  .p0-accordion.-type2 .p0-accordion_head {
    display: none
  }
}

.p0-accordion.-type2 .p0-accordion_head_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  color: #47c9f4;
  text-align: center
}

.p0-accordion.-type2 .p0-accordion_head_ttl .-close {
  display: inline
}

.p0-accordion.-type2 .p0-accordion_head_ttl .-open {
  display: none
}

.p0-accordion.-type2 .p0-accordion_head_ttl:after {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 11px;
  content: "";
  margin-left: 10px;
  background-image: url("../images/common/icon_gt2_sb.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat
}

.p0-accordion.-type2 .p0-accordion_head.is-active .p0-accordion_head_ttl:after {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1)
}

.p0-accordion.-type2 .p0-accordion_head.is-active .-close {
  display: none
}

.p0-accordion.-type2 .p0-accordion_head.is-active .-open {
  display: inline
}

.p0-accordion.-type2 .p0-accordion_body {
  padding-top: 20px;
  box-sizing: border-box
}

.p0-accordion.-type2 .p0-accordion_body.-close {
  display: none
}

@media all and (min-width: 800px) {
  .p0-accordion.-type2 .p0-accordion_body.-close {
    display: block !important
  }
}

.p0-accordion.-type3 .p0-accordion_head {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  margin-bottom: 4px;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  background-color: #0d6fb8;
  cursor: pointer
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_head {
    min-height: 80px;
    padding: 20px 90px 20px 30px
  }
}

.p0-accordion.-type3 .p0-accordion_head:first-of-type {
  margin-top: 0
}

.p0-accordion.-type3 .p0-accordion_head:before {
  flex-shrink: 0;
  position: relative;
  width: 27px;
  height: 33px;
  content: "";
  box-sizing: border-box;
  line-height: 1;
  background-image: url("../images/common/txt/txt_q.png");
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_head:before {
    width: 30px;
    height: 37px
  }
}

.p0-accordion.-type3 .p0-accordion_head_ttl {
  position: relative;
  padding-top: 2px;
  padding-left: 10px;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  color: #fff;
  line-height: 1.5
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_head_ttl {
    font-size: 2rem
  }
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_head_ttl {
    padding-top: 4px;
    padding-left: 20px
  }
}

.p0-accordion.-type3 .p0-accordion_head.is-active.p0-accordion_head:after {
  -webkit-transform: translate(0, -50%) scale(1, -1);
  transform: translate(0, -50%) scale(1, -1)
}

.p0-accordion.-type3 .p0-accordion_head:after {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 23px;
  height: 23px;
  content: "";
  background-image: url("../images/common/icon_ctri_btm_ora.png");
  background-position: center center;
  background-size: 100% auto;
  background-repeat: no-repeat
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_head:after {
    right: 30px;
    width: 30px;
    height: 30px
  }
}

.p0-accordion.-type3 .p0-accordion_body {
  padding: 25px 15px 15px 20px;
  box-sizing: border-box;
  background-color: #f5f5f5
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_body {
    padding: 40px 30px 35px 30px
  }
}

.p0-accordion.-type3 .p0-accordion_body.-close {
  display: none
}

.p0-accordion.-type3 .p0-accordion_body_inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative
}

.p0-accordion.-type3 .p0-accordion_body_inner:before {
  flex-shrink: 0;
  position: relative;
  width: 27px;
  height: 33px;
  content: "";
  line-height: 1;
  background-image: url("../images/common/txt/txt_a.png");
  background-position: left bottom;
  background-size: auto 100%;
  background-repeat: no-repeat
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_body_inner:before {
    width: 30px;
    height: 37px
  }
}

.p0-accordion.-type3 .p0-accordion_body_txt {
  width: 100%;
  overflow: hidden;
  padding-left: 10px;
  box-sizing: border-box
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_body_txt {
    padding-left: 20px
  }
}

.p0-accordion.-type3 .p0-accordion_body_txt p {
  margin-bottom: 10px
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_body_txt p {
    margin-bottom: 20px
  }
}

.p0-accordion.-type3 .p0-accordion_body_txt img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: top;
  margin-bottom: 1em
}

.p0-accordion.-type3 .p0-accordion_body_txt .p0-btn2, .p0-accordion.-type3 .p0-accordion_body_txt .p0-btn3 {
  margin-bottom: 10px
}

@media all and (min-width: 800px) {
  .p0-accordion.-type3 .p0-accordion_body_txt .p0-btn2, .p0-accordion.-type3 .p0-accordion_body_txt .p0-btn3 {
    margin-bottom: 20px
  }
}

.p0-accordion.-type3 .p0-accordion_body_txt .p0-btn2 a, .p0-accordion.-type3 .p0-accordion_body_txt .p0-btn3 a {
  color: #001b2f;
  text-decoration: none
}

.p1-logo.-reska {
  display: none;
  width: 180px
}

@media all and (min-width: 800px) {
  .p1-logo.-reska {
    display: block;
    width: 180px
  }
}

@media all and (min-width: 1000px) {
  .p1-logo.-reska {
    width: 200px
  }
}

.p1-logo.-reska img {
  width: 100%;
  height: auto
}

.p1-logo.-reska.-splogo {
  display: block;
  position: relative;
  top: 20px;
  left: 5%;
  z-index: 1;
  width: 160px
}

@media all and (min-width: 375px) {
  .p1-logo.-reska.-splogo {
    width: 180px
  }
}

@media all and (min-width: 800px) {
  .p1-logo.-reska.-splogo {
    display: none
  }
}

.p1-logo.-reska.-hero {
  display: block;
  position: relative;
  z-index: 20;
  width: 48.125vw;
  padding-top: 120px;
  box-sizing: border-box
}

@media all and (min-width: 375px) {
  .p1-logo.-reska.-hero {
    width: 49.2307692307692vw
  }
}

@media all and (min-width: 520px) {
  .p1-logo.-reska.-hero {
    width: 52vw
  }
}

@media all and (min-width: 800px) {
  .p1-logo.-reska.-hero {
    width: 478px;
    padding-top: 50px
  }
}

.p1-logo.-reska.-hero img {
  -webkit-transform: translate(-5.58659%, -13.6612%);
  transform: translate(-5.58659%, -13.6612%)
}

.p1-logo.-marespera {
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 72px
}

@media all and (min-width: 375px) {
  .p1-logo.-marespera {
    bottom: 20px;
    width: 90px
  }
}

@media all and (min-width: 800px) {
  .p1-logo.-marespera {
    position: relative;
    bottom: auto;
    left: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    width: 58px
  }
}

@media all and (min-width: 1000px) {
  .p1-logo.-marespera {
    width: 65px
  }
}

.p1-logo.-marespera img {
  width: 100%;
  height: auto
}

.p1-logo.-marespera.-hero {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1001;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  width: 67px
}

@media all and (min-width: 800px) {
  .p1-logo.-marespera.-hero {
    top: 30px;
    left: auto;
    right: 0;
    width: 90px
  }
}

.p1-logo.-marespera.-hero.js-isopen {
  -webkit-animation: 0.6s both zIndexMinus;
  animation: 0.6s both zIndexMinus
}

.p1-logo.-marespera.-hero.js-isclose {
  -webkit-animation: 0.4s both zIndexPlus;
  animation: 0.4s both zIndexPlus
}

.p1-logo_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center
}

.p1-drawer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  -webkit-transform: translate(-50%, -100vh);
  transform: translate(-50%, -100vh)
}

@media all and (min-width: 800px) {
  .p1-drawer {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
    width: 100%;
    height: 100%
  }
}

.p1-drawer.js-isopen {
  -webkit-animation: 0.6s ease-out 0s 1 both drawerIn;
  animation: 0.6s ease-out 0s 1 both drawerIn
}

.p1-drawer.js-isclose {
  -webkit-animation: 0.4s ease-in 0s 1 both drawerOut;
  animation: 0.4s ease-in 0s 1 both drawerOut
}

.p1-drawer-open {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 0;
  z-index: 1200;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  cursor: pointer
}

@media all and (min-width: 800px) {
  .p1-drawer-open {
    display: none
  }
}

.p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
  display: block;
  position: absolute;
  height: 5px;
  width: 40px;
  content: '';
  border-radius: 100vh;
  background-color: #001b2f;
  transition: 0.3s
}

.p1-drawer-open span::before {
  bottom: 12px;
  -webkit-transform-origin: top left;
  transform-origin: top left
}

.p1-drawer-open span::after {
  top: 12px;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left
}

.p1-drawer-open.js-isopen span {
  background-color: rgba(0, 27, 47, 0)
}

.p1-drawer-open.js-isopen span::before {
  -webkit-transform: translate(5px, 0) rotate(40.7deg);
  transform: translate(5px, 0) rotate(40.7deg);
  background-color: #fff
}

.p1-drawer-open.js-isopen span::after {
  -webkit-transform: translate(5px, 1px) rotate(-40.7deg);
  transform: translate(5px, 1px) rotate(-40.7deg);
  background-color: #fff
}

.p1-gnav {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%
}

.p1-gnav_content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat
}

@media all and (min-width: 800px) {
  .p1-gnav_content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100%;
    height: 100%;
    padding: 0;
    background-image: none;
    background-color: transparent
  }
}

.p1-gnav_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 30px 0 0 0;
  box-sizing: border-box
}

@media all and (min-width: 375px) {
  .p1-gnav_list {
    margin-bottom: 40px;
    padding-top: 40px
  }
}

@media all and (min-width: 800px) {
  .p1-gnav_list {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 540px;
    height: 100%;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 15px;
    padding: 0
  }
}

@media all and (min-width: 900px) {
  .p1-gnav_list {
    margin-left: 30px;
    margin-right: 25px
  }
}

@media all and (min-width: 1000px) {
  .p1-gnav_list {
    max-width: 626px;
    margin-left: 50px;
    margin-right: 50px
  }
}

.p1-gnav_item {
  display: block;
  position: relative;
  box-sizing: border-box;
  color: #fff
}

@media all and (min-width: 800px) {
  .p1-gnav_item {
    height: 100%
  }
}

.p1-gnav_item.-pconly {
  display: none
}

@media all and (min-width: 800px) {
  .p1-gnav_item.-pconly {
    display: block
  }
}

.p1-gnav_itemInner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  color: #fff;
  text-align: left;
  line-height: 1
}

@media all and (min-width: 375px) {
  .p1-gnav_itemInner {
    padding: 12px 0
  }
}

@media all and (min-width: 800px) {
  .p1-gnav_itemInner {
    justify-content: center;
    padding: 0;
    font-size: 1.5rem;
    text-align: center
  }
}

@media all and (min-width: 1120px) {
  .p1-gnav_itemInner {
    font-size: 1.6rem
  }
}

.p1-gnav_item.-btn {
  width: 100%
}

@media all and (min-width: 800px) {
  .p1-gnav_item.-btn {
    width: auto
  }
}

.l-header.js-isscroll .p1-gnav {
  background: none
}

@media all and (min-width: 800px) {
  .l-header.js-isscroll .p1-gnav {
    border-style: none;
    border-radius: 0
  }
}

@media all and (min-width: 800px) {
  .l-header.js-isscroll .p1-gnav .p1-gnav_list.-btn {
    margin-right: 10px
  }
}

.l-header.js-noscroll .p1-gnav {
  background: rgba(245, 245, 245, 0)
}

@media all and (min-width: 800px) {
  .l-header.js-noscroll .p1-gnav {
    background: rgba(245, 245, 245, 0.9)
  }
}

.p1-fixBtmNav {
  display: none;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #fff
}

@media all and (min-width: 800px) {
  .p1-fixBtmNav {
    position: relative;
    height: 60px
  }
}

@media all and (min-width: 1000px) {
  .p1-fixBtmNav {
    height: 70px
  }
}

.p1-fixBtmNav.js-isscroll {
  display: flex;
  position: fixed
}

@media all and (min-width: 800px) {
  .p1-fixBtmNav.js-isscroll {
    -webkit-animation: 0.3s fadeIn;
    animation: 0.3s fadeIn
  }
}

.p1-fixBtmNav.js-noscroll {
  display: flex;
  position: fixed
}

@media all and (min-width: 800px) {
  .p1-fixBtmNav.js-noscroll {
    display: flex;
    position: relative
  }
}

.p1-fixBtmNav_item {
  width: 50%;
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  cursor: pointer
}

@media all and (min-width: 375px) {
  .p1-fixBtmNav_item {
    font-size: 1.5rem
  }
}

@media all and (min-width: 520px) {
  .p1-fixBtmNav_item {
    font-size: 1.9rem
  }
}

@media all and (min-width: 800px) {
  .p1-fixBtmNav_item {
    font-size: 3rem
  }
}

@media all and (min-width: 1000px) {
  .p1-fixBtmNav_item {
    font-size: 2.6rem
  }
}

.p1-fixBtmNav_item.-download {
  color: #001b2f;
  background-color: #ff9f00
}

.p1-fixBtmNav_item.-trial {
  color: #fff;
  background-color: #425360
}

.p1-fixBtmNav_itemInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

.p1-fixBtmNav_itemInner_img {
  width: auto;
  height: 13px;
  margin-top: 1px;
  margin-left: 5px
}

@media all and (min-width: 375px) {
  .p1-fixBtmNav_itemInner_img {
    height: 15px
  }
}

@media all and (min-width: 520px) {
  .p1-fixBtmNav_itemInner_img {
    height: 19px;
    margin-top: 2px
  }
}

@media all and (min-width: 800px) {
  .p1-fixBtmNav_itemInner_img {
    height: 30px;
    margin-top: 1px;
    margin-left: 11px
  }
}

@media all and (min-width: 1000px) {
  .p1-fixBtmNav_itemInner_img {
    height: 18px;
    margin-left: 11px
  }
}

.p2-copyright {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #001b2f;
  font-size: 1rem;
  text-align: center;
  line-height: 1
}

@media all and (min-width: 800px) {
  .p2-copyright {
    font-size: 1.2rem
  }
}

@media all and (min-width: 620px) {
  .p2-copyright {
    text-align: right
  }
}

.p2-ftinfo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px
}

@media all and (min-width: 620px) {
  .p2-ftinfo {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 0
  }
}

.p2-ftinfo_logo {
  width: 130px;
  margin-bottom: 10px
}

@media all and (min-width: 620px) {
  .p2-ftinfo_logo {
    width: 130px;
    margin-bottom: 0
  }
}

@media all and (min-width: 800px) {
  .p2-ftinfo_logo {
    width: 144px
  }
}

.p2-ftinfo_txt {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box
}

@media all and (min-width: 620px) {
  .p2-ftinfo_txt {
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px
  }
}

@media all and (min-width: 800px) {
  .p2-ftinfo_txt {
    padding-left: 20px;
    padding-right: 20px
  }
}

.p2-ftinfo_people {
  display: none
}

@media all and (min-width: 1000px) {
  .p2-ftinfo_people {
    display: block;
    width: 208px
  }
}

.p2-ftinfo_catchcopy {
  width: 250px;
  margin-bottom: 25px;
  box-sizing: border-box
}

@media all and (min-width: 620px) {
  .p2-ftinfo_catchcopy {
    width: 178px;
    margin-bottom: 20px
  }
}

.p2-ftinfo_companyname {
  display: block;
  margin-bottom: 15px;
  color: #001b2f
}

.p2-ftinfo_companyname .-kana {
  padding: 0 0 3px 0;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 8px;
  text-align: right
}

@media all and (min-width: 800px) {
  .p2-ftinfo_companyname .-kana {
    letter-spacing: 10px
  }
}

.p2-ftinfo_companyname .-name {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem
}

@media all and (min-width: 800px) {
  .p2-ftinfo_companyname .-name {
    font-size: 2rem
  }
}

.p2-ftinfo_companyname a {
  color: #001b2f
}

.p2-ftinfo_address {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem
}

@media all and (min-width: 800px) {
  .p2-ftinfo_address {
    font-size: 1.4rem
  }
}

.p2-sns {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 157px;
  margin-bottom: 20px
}

@media all and (min-width: 800px) {
  .p2-sns {
    width: 188px;
    margin-bottom: 30px
  }
}

.p2-sns_item {
  display: block;
  width: 40px
}

@media all and (min-width: 800px) {
  .p2-sns_item {
    width: 48px
  }
}

.p2-sns_item img {
  width: 100%;
  height: auto
}

.p3-headline {
  position: relative;
  margin: 10px auto;
  color: #001b2f;
  text-align: center
}

@media all and (min-width: 800px) {
  .p3-headline {
    margin: 20px auto
  }
}

.p3-headline .ttl {
  font-size: 2.6rem;
  line-height: 1.2
}

@media all and (min-width: 800px) {
  .p3-headline .ttl {
    font-size: 4.8rem
  }
}

.p3-headline .ttl.-min {
  font-family: 'Shippori Mincho', serif;
  font-weight: 500;
  font-weight: 500;
  padding-bottom: 5px
}

.p3-headline .ttl.-go {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700
}

.p3-headline .ttl.-feature {
  text-align: left
}

@media all and (min-width: 580px) {
  .p3-headline .ttl.-feature {
    text-align: center
  }
}

@media all and (min-width: 800px) {
  .p3-headline .ttl.-feature {
    text-align: left
  }
}

@media all and (min-width: 1120px) {
  .p3-headline .ttl.-feature {
    text-align: center
  }
}

.p3-headline.-function {
  margin: 0 auto
}

@media all and (min-width: 800px) {
  .p3-headline.-function {
    margin: 0 auto
  }
}

@media all and (min-width: 800px) {
  .p3-headline.-function .subttl {
    padding-top: 40px
  }
}

.p3-headline.-function .subttl_line {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center
}

@media all and (min-width: 800px) {
  .p3-headline.-function .subttl_line {
    display: block
  }
}

.p3-headline.-function .subttl_line1:after {
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  margin-left: 10px;
  margin-right: 7px;
  background-color: #0d6fb8
}

@media all and (min-width: 800px) {
  .p3-headline.-function .subttl_line1:after {
    margin-left: 15px;
    margin-right: 0
  }
}

.p3-headline.-function .subttl_line2 {
  font-size: 3.2rem
}

@media all and (min-width: 800px) {
  .p3-headline.-function .subttl_line2 {
    font-size: 4.8rem
  }
}

@media all and (min-width: 800px) {
  .p3-headline.-function .subttl_line2 {
    margin-bottom: 50px
  }
}

.p3-headline.-case {
  margin: 0 auto
}

@media all and (min-width: 800px) {
  .p3-headline.-case {
    margin: 0 auto
  }
}

.p3-headline.-case .subttl_line1 {
  margin-bottom: 15px
}

@media all and (min-width: 800px) {
  .p3-headline.-case .subttl_line1 {
    margin-bottom: 20px
  }
}

.p3-headline.-case .subttl_line1:after {
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  margin-left: 10px;
  background-color: #0d6fb8
}

@media all and (min-width: 800px) {
  .p3-headline.-case .subttl_line1:after {
    margin-left: 15px
  }
}

.p3-headline.-case .subttl_line2 {
  font-size: 1.4rem
}

@media all and (min-width: 800px) {
  .p3-headline.-case .subttl_line2 {
    font-size: 2.8rem
  }
}

@media all and (min-width: 800px) {
  .p3-headline.-case .subttl_line2 {
    margin-bottom: 30px
  }
}

.p3-headline .subttl {
  position: relative;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: left
}

.p3-headline .subttl_line {
  margin-bottom: 20px
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_line {
    margin-bottom: 0
  }
}

.p3-headline .subttl_line1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 1.8rem
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_line1 {
    font-size: 2.4rem
  }
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_line1 {
    width: auto;
    margin-bottom: 10px
  }
}

.p3-headline .subttl_line1_sup {
  flex-shrink: 0
}

.p3-headline .subttl_line1 .-num {
  width: auto;
  height: 26px;
  margin-left: 5px;
  vertical-align: bottom
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_line1 .-num {
    height: 37px
  }
}

.p3-headline .subttl_line2 {
  flex-shrink: 0
}

.p3-headline .subttl_mockup {
  position: relative;
  z-index: 5;
  width: 242px;
  margin: 0 auto
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_mockup {
    position: absolute;
    top: 0;
    right: 0;
    width: 32.1428571428571vw;
    margin-right: 0
  }
}

@media all and (min-width: 1120px) {
  .p3-headline .subttl_mockup {
    width: 360px
  }
}

.p3-headline .subttl_band {
  display: block;
  position: relative;
  z-index: 7;
  width: 100%;
  margin-top: -120px;
  padding: 25px 0 35px;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  color: #fff;
  line-height: 1.6;
  text-align: left;
  background-color: #0d6fb8
}

@media all and (min-width: 375px) {
  .p3-headline .subttl_band {
    font-size: 2.2rem
  }
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_band {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    bottom: auto;
    z-index: 3;
    margin-top: 0;
    padding: 0;
    min-height: 240px;
    font-size: 2.3rem;
    line-height: 1.9
  }
}

@media all and (min-width: 900px) {
  .p3-headline .subttl_band {
    font-size: 2.7rem
  }
}

@media all and (min-width: 1120px) {
  .p3-headline .subttl_band {
    min-height: 280px;
    font-size: 3.2rem
  }
}

.p3-headline .subttl_band_en {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 51px
}

@media all and (min-width: 800px) {
  .p3-headline .subttl_band_en {
    height: 65px
  }
}

.p3-headline .subttl_band_en img {
  width: auto;
  height: 100%
}

.p3-text.-shadow.-bk {
  -moz-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
  -webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
  -ms-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36)
}

.p3-text.-shadow.-wh {
  -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px #fff;
  -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px #fff;
  -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px #fff;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px #fff
}

.p3-text .-ora {
  color: #ff7800 !important
}

.p3-text .-blue {
  color: #0d6fb8 !important
}

.p3-text.-lead01 {
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  color: #001b2f;
  text-align: left;
  line-height: 1.8
}

@media all and (min-width: 800px) {
  .p3-text.-lead01 {
    font-size: 2.4rem
  }
}

@media all and (min-width: 800px) {
  .p3-text.-lead01 {
    text-align: center
  }
}

.p3-text.-lead01.-feature {
  text-align: left
}

@media all and (min-width: 700px) {
  .p3-text.-lead01.-feature {
    text-align: left
  }
}

@media all and (min-width: 800px) {
  .p3-text.-lead01.-feature {
    text-align: left
  }
}

@media all and (min-width: 1120px) {
  .p3-text.-lead01.-feature {
    text-align: center
  }
}

.p3-text.-t01 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  color: #444;
  text-align: justify;
  line-height: 2
}

@media all and (min-width: 800px) {
  .p3-text.-t01 {
    font-size: 1.4rem
  }
}

.p3-text.-t01.-center {
  text-align: center
}

.p3-text.-t01.-bold {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700
}

.p3-text.-t02 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #444;
  text-align: justify;
  line-height: 2
}

@media all and (min-width: 800px) {
  .p3-text.-t02 {
    font-size: 1.6rem
  }
}

.p3-text.-t02.-center {
  text-align: center
}

.p3-text.-t03 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #444;
  line-height: 2;
  text-align: justify
}

@media all and (min-width: 800px) {
  .p3-text.-t03 {
    line-height: 2.2
  }
}

@media all and (min-width: 1000px) {
  .p3-text.-t03 {
    line-height: 2
  }
}

.p3-text.-t03.-center {
  text-align: center
}

.p3-text.-t04 {
  font-family: 'Shippori Mincho', serif;
  font-weight: 500;
  font-weight: 600;
  font-size: 1.5rem;
  color: #001b2f;
  line-height: 1.75;
  text-align: justify
}

@media all and (min-width: 800px) {
  .p3-text.-t04 {
    font-size: 2.8rem
  }
}

@media all and (min-width: 800px) {
  .p3-text.-t04 {
    line-height: 1.55
  }
}

.p3-text.-t04.-center {
  text-align: center
}

.p3-text.-t05 {
  font-size: 1.4rem;
  line-height: 1.6
}

@media all and (min-width: 800px) {
  .p3-text.-t05 {
    font-size: 1.2rem
  }
}

@media all and (min-width: 800px) {
  .p3-text.-t05 {
    line-height: 2.4
  }
}

.p3-text.-t06 {
  font-family: 'Shippori Mincho', serif;
  font-weight: 500;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.7;
  text-align: justify
}

@media all and (min-width: 520px) {
  .p3-text.-t06 {
    font-size: 1.5rem
  }
}

@media all and (min-width: 800px) {
  .p3-text.-t06 {
    font-size: 1rem
  }
}

.p3-text.-link01 {
  color: #0d6fb8;
  border-bottom: 1px solid #0d6fb8;
  text-decoration: none
}

.p3-bg.-wh {
  background-color: #fff
}

.p3-bg.-db {
  background-color: #0d6fb8
}

.p3-bg.-feature {
  padding-bottom: 150px;
  box-sizing: border-box;
  background-image: url("../images/front/feature/people_sp.png");
  background-position: center bottom 20px;
  background-repeat: no-repeat;
  background-size: auto 86%
}

@media all and (min-width: 800px) {
  .p3-bg.-feature {
    padding-bottom: 0;
    background-image: url("../images/front/feature/people_pc.png");
    background-position: center top 360px;
    background-size: auto 75.9821428571429vw
  }
}

@media all and (min-width: 1120px) {
  .p3-bg.-feature {
    background-position: center top 250px;
    background-size: auto 851px
  }
}

.p3-img.-i01 {
  display: none;
  position: absolute;
  top: -34px;
  left: -15px;
  width: 184px;
  height: 167px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

@media all and (min-width: 800px) {
  .p3-img.-i01 {
    display: block;
    top: -16px;
    left: -25px;
    width: 230px;
    height: 208px
  }
}

.u-m0 {
  margin: 0px !important
}

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important
}

.u-mt0 {
  margin-top: 0px !important
}

.u-mb0 {
  margin-bottom: 0px !important
}

.u-ml0 {
  margin-left: 0px !important
}

.u-mr0 {
  margin-right: 0px !important
}

.u-p0 {
  padding: 0px !important
}

.u-pt0 {
  padding-top: 0px !important
}

.u-pb0 {
  padding-bottom: 0px !important
}

.u-pl0 {
  padding-left: 0px !important
}

.u-pr0 {
  padding-right: 0px !important
}

.u-m5 {
  margin: 5px !important
}

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important
}

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important
}

.u-mt5 {
  margin-top: 5px !important
}

.u-mb5 {
  margin-bottom: 5px !important
}

.u-ml5 {
  margin-left: 5px !important
}

.u-mr5 {
  margin-right: 5px !important
}

.u-p5 {
  padding: 5px !important
}

.u-pt5 {
  padding-top: 5px !important
}

.u-pb5 {
  padding-bottom: 5px !important
}

.u-pl5 {
  padding-left: 5px !important
}

.u-pr5 {
  padding-right: 5px !important
}

.u-m10 {
  margin: 10px !important
}

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important
}

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important
}

.u-mt10 {
  margin-top: 10px !important
}

.u-mb10 {
  margin-bottom: 10px !important
}

.u-ml10 {
  margin-left: 10px !important
}

.u-mr10 {
  margin-right: 10px !important
}

.u-p10 {
  padding: 10px !important
}

.u-pt10 {
  padding-top: 10px !important
}

.u-pb10 {
  padding-bottom: 10px !important
}

.u-pl10 {
  padding-left: 10px !important
}

.u-pr10 {
  padding-right: 10px !important
}

.u-m15 {
  margin: 15px !important
}

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important
}

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important
}

.u-mt15 {
  margin-top: 15px !important
}

.u-mb15 {
  margin-bottom: 15px !important
}

.u-ml15 {
  margin-left: 15px !important
}

.u-mr15 {
  margin-right: 15px !important
}

.u-p15 {
  padding: 15px !important
}

.u-pt15 {
  padding-top: 15px !important
}

.u-pb15 {
  padding-bottom: 15px !important
}

.u-pl15 {
  padding-left: 15px !important
}

.u-pr15 {
  padding-right: 15px !important
}

.u-m20 {
  margin: 20px !important
}

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important
}

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important
}

.u-mt20 {
  margin-top: 20px !important
}

.u-mb20 {
  margin-bottom: 20px !important
}

.u-ml20 {
  margin-left: 20px !important
}

.u-mr20 {
  margin-right: 20px !important
}

.u-p20 {
  padding: 20px !important
}

.u-pt20 {
  padding-top: 20px !important
}

.u-pb20 {
  padding-bottom: 20px !important
}

.u-pl20 {
  padding-left: 20px !important
}

.u-pr20 {
  padding-right: 20px !important
}

.u-m25 {
  margin: 25px !important
}

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important
}

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important
}

.u-mt25 {
  margin-top: 25px !important
}

.u-mb25 {
  margin-bottom: 25px !important
}

.u-ml25 {
  margin-left: 25px !important
}

.u-mr25 {
  margin-right: 25px !important
}

.u-p25 {
  padding: 25px !important
}

.u-pt25 {
  padding-top: 25px !important
}

.u-pb25 {
  padding-bottom: 25px !important
}

.u-pl25 {
  padding-left: 25px !important
}

.u-pr25 {
  padding-right: 25px !important
}

.u-m30 {
  margin: 30px !important
}

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important
}

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important
}

.u-mt30 {
  margin-top: 30px !important
}

.u-mb30 {
  margin-bottom: 30px !important
}

.u-ml30 {
  margin-left: 30px !important
}

.u-mr30 {
  margin-right: 30px !important
}

.u-p30 {
  padding: 30px !important
}

.u-pt30 {
  padding-top: 30px !important
}

.u-pb30 {
  padding-bottom: 30px !important
}

.u-pl30 {
  padding-left: 30px !important
}

.u-pr30 {
  padding-right: 30px !important
}

.u-m35 {
  margin: 35px !important
}

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important
}

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important
}

.u-mt35 {
  margin-top: 35px !important
}

.u-mb35 {
  margin-bottom: 35px !important
}

.u-ml35 {
  margin-left: 35px !important
}

.u-mr35 {
  margin-right: 35px !important
}

.u-p35 {
  padding: 35px !important
}

.u-pt35 {
  padding-top: 35px !important
}

.u-pb35 {
  padding-bottom: 35px !important
}

.u-pl35 {
  padding-left: 35px !important
}

.u-pr35 {
  padding-right: 35px !important
}

.u-m40 {
  margin: 40px !important
}

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important
}

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important
}

.u-mt40 {
  margin-top: 40px !important
}

.u-mb40 {
  margin-bottom: 40px !important
}

.u-ml40 {
  margin-left: 40px !important
}

.u-mr40 {
  margin-right: 40px !important
}

.u-p40 {
  padding: 40px !important
}

.u-pt40 {
  padding-top: 40px !important
}

.u-pb40 {
  padding-bottom: 40px !important
}

.u-pl40 {
  padding-left: 40px !important
}

.u-pr40 {
  padding-right: 40px !important
}

.u-m45 {
  margin: 45px !important
}

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important
}

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important
}

.u-mt45 {
  margin-top: 45px !important
}

.u-mb45 {
  margin-bottom: 45px !important
}

.u-ml45 {
  margin-left: 45px !important
}

.u-mr45 {
  margin-right: 45px !important
}

.u-p45 {
  padding: 45px !important
}

.u-pt45 {
  padding-top: 45px !important
}

.u-pb45 {
  padding-bottom: 45px !important
}

.u-pl45 {
  padding-left: 45px !important
}

.u-pr45 {
  padding-right: 45px !important
}

.u-m50 {
  margin: 50px !important
}

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important
}

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important
}

.u-mt50 {
  margin-top: 50px !important
}

.u-mb50 {
  margin-bottom: 50px !important
}

.u-ml50 {
  margin-left: 50px !important
}

.u-mr50 {
  margin-right: 50px !important
}

.u-p50 {
  padding: 50px !important
}

.u-pt50 {
  padding-top: 50px !important
}

.u-pb50 {
  padding-bottom: 50px !important
}

.u-pl50 {
  padding-left: 50px !important
}

.u-pr50 {
  padding-right: 50px !important
}

.u-m55 {
  margin: 55px !important
}

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important
}

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important
}

.u-mt55 {
  margin-top: 55px !important
}

.u-mb55 {
  margin-bottom: 55px !important
}

.u-ml55 {
  margin-left: 55px !important
}

.u-mr55 {
  margin-right: 55px !important
}

.u-p55 {
  padding: 55px !important
}

.u-pt55 {
  padding-top: 55px !important
}

.u-pb55 {
  padding-bottom: 55px !important
}

.u-pl55 {
  padding-left: 55px !important
}

.u-pr55 {
  padding-right: 55px !important
}

.u-m60 {
  margin: 60px !important
}

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important
}

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important
}

.u-mt60 {
  margin-top: 60px !important
}

.u-mb60 {
  margin-bottom: 60px !important
}

.u-ml60 {
  margin-left: 60px !important
}

.u-mr60 {
  margin-right: 60px !important
}

.u-p60 {
  padding: 60px !important
}

.u-pt60 {
  padding-top: 60px !important
}

.u-pb60 {
  padding-bottom: 60px !important
}

.u-pl60 {
  padding-left: 60px !important
}

.u-pr60 {
  padding-right: 60px !important
}

.u-m65 {
  margin: 65px !important
}

.u-mtb65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important
}

.u-mlr65 {
  margin-left: 65px !important;
  margin-right: 65px !important
}

.u-mt65 {
  margin-top: 65px !important
}

.u-mb65 {
  margin-bottom: 65px !important
}

.u-ml65 {
  margin-left: 65px !important
}

.u-mr65 {
  margin-right: 65px !important
}

.u-p65 {
  padding: 65px !important
}

.u-pt65 {
  padding-top: 65px !important
}

.u-pb65 {
  padding-bottom: 65px !important
}

.u-pl65 {
  padding-left: 65px !important
}

.u-pr65 {
  padding-right: 65px !important
}

.u-m70 {
  margin: 70px !important
}

.u-mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important
}

.u-mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important
}

.u-mt70 {
  margin-top: 70px !important
}

.u-mb70 {
  margin-bottom: 70px !important
}

.u-ml70 {
  margin-left: 70px !important
}

.u-mr70 {
  margin-right: 70px !important
}

.u-p70 {
  padding: 70px !important
}

.u-pt70 {
  padding-top: 70px !important
}

.u-pb70 {
  padding-bottom: 70px !important
}

.u-pl70 {
  padding-left: 70px !important
}

.u-pr70 {
  padding-right: 70px !important
}

.u-m75 {
  margin: 75px !important
}

.u-mtb75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important
}

.u-mlr75 {
  margin-left: 75px !important;
  margin-right: 75px !important
}

.u-mt75 {
  margin-top: 75px !important
}

.u-mb75 {
  margin-bottom: 75px !important
}

.u-ml75 {
  margin-left: 75px !important
}

.u-mr75 {
  margin-right: 75px !important
}

.u-p75 {
  padding: 75px !important
}

.u-pt75 {
  padding-top: 75px !important
}

.u-pb75 {
  padding-bottom: 75px !important
}

.u-pl75 {
  padding-left: 75px !important
}

.u-pr75 {
  padding-right: 75px !important
}

.u-m80 {
  margin: 80px !important
}

.u-mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important
}

.u-mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important
}

.u-mt80 {
  margin-top: 80px !important
}

.u-mb80 {
  margin-bottom: 80px !important
}

.u-ml80 {
  margin-left: 80px !important
}

.u-mr80 {
  margin-right: 80px !important
}

.u-p80 {
  padding: 80px !important
}

.u-pt80 {
  padding-top: 80px !important
}

.u-pb80 {
  padding-bottom: 80px !important
}

.u-pl80 {
  padding-left: 80px !important
}

.u-pr80 {
  padding-right: 80px !important
}

.u-m85 {
  margin: 85px !important
}

.u-mtb85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important
}

.u-mlr85 {
  margin-left: 85px !important;
  margin-right: 85px !important
}

.u-mt85 {
  margin-top: 85px !important
}

.u-mb85 {
  margin-bottom: 85px !important
}

.u-ml85 {
  margin-left: 85px !important
}

.u-mr85 {
  margin-right: 85px !important
}

.u-p85 {
  padding: 85px !important
}

.u-pt85 {
  padding-top: 85px !important
}

.u-pb85 {
  padding-bottom: 85px !important
}

.u-pl85 {
  padding-left: 85px !important
}

.u-pr85 {
  padding-right: 85px !important
}

.u-m90 {
  margin: 90px !important
}

.u-mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important
}

.u-mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important
}

.u-mt90 {
  margin-top: 90px !important
}

.u-mb90 {
  margin-bottom: 90px !important
}

.u-ml90 {
  margin-left: 90px !important
}

.u-mr90 {
  margin-right: 90px !important
}

.u-p90 {
  padding: 90px !important
}

.u-pt90 {
  padding-top: 90px !important
}

.u-pb90 {
  padding-bottom: 90px !important
}

.u-pl90 {
  padding-left: 90px !important
}

.u-pr90 {
  padding-right: 90px !important
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important
}

@media all and (min-width: 800px) {
  .u-m-pcAuto-spLeft {
    margin-left: auto !important;
    margin-right: auto !important
  }
}

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important
}

@media all and (min-width: 800px) {
  .u-m-pcAuto-spRight {
    margin-left: auto !important;
    margin-right: auto !important
  }
}

.u-mb10_half {
  margin-bottom: 5px !important
}

@media all and (min-width: 800px) {
  .u-mb10_half {
    margin-bottom: 10px !important
  }
}

.u-mb20_half {
  margin-bottom: 10px !important
}

@media all and (min-width: 800px) {
  .u-mb20_half {
    margin-bottom: 20px !important
  }
}

.u-mb30_half {
  margin-bottom: 15px !important
}

@media all and (min-width: 800px) {
  .u-mb30_half {
    margin-bottom: 30px !important
  }
}

.u-mb40_half {
  margin-bottom: 20px !important
}

@media all and (min-width: 800px) {
  .u-mb40_half {
    margin-bottom: 40px !important
  }
}

.u-mb50_half {
  margin-bottom: 25px !important
}

@media all and (min-width: 800px) {
  .u-mb50_half {
    margin-bottom: 50px !important
  }
}

.u-mb60_half {
  margin-bottom: 30px !important
}

@media all and (min-width: 800px) {
  .u-mb60_half {
    margin-bottom: 60px !important
  }
}

.u-mb70_half {
  margin-bottom: 35px !important
}

@media all and (min-width: 800px) {
  .u-mb70_half {
    margin-bottom: 70px !important
  }
}

.u-mb80_half {
  margin-bottom: 40px !important
}

@media all and (min-width: 800px) {
  .u-mb80_half {
    margin-bottom: 80px !important
  }
}

.u-mb90_half {
  margin-bottom: 45px !important
}

@media all and (min-width: 800px) {
  .u-mb90_half {
    margin-bottom: 90px !important
  }
}

.u-mb100_half {
  margin-bottom: 50px !important
}

@media all and (min-width: 800px) {
  .u-mb100_half {
    margin-bottom: 100px !important
  }
}

.u-mb110_half {
  margin-bottom: 55px !important
}

@media all and (min-width: 800px) {
  .u-mb110_half {
    margin-bottom: 110px !important
  }
}

.u-mb120_half {
  margin-bottom: 60px !important
}

@media all and (min-width: 800px) {
  .u-mb120_half {
    margin-bottom: 120px !important
  }
}

.u-mb130_half {
  margin-bottom: 65px !important
}

@media all and (min-width: 800px) {
  .u-mb130_half {
    margin-bottom: 130px !important
  }
}

.u-mb140_half {
  margin-bottom: 70px !important
}

@media all and (min-width: 800px) {
  .u-mb140_half {
    margin-bottom: 140px !important
  }
}

.u-mb150_half {
  margin-bottom: 75px !important
}

@media all and (min-width: 800px) {
  .u-mb150_half {
    margin-bottom: 150px !important
  }
}

.u-mb160_half {
  margin-bottom: 80px !important
}

@media all and (min-width: 800px) {
  .u-mb160_half {
    margin-bottom: 160px !important
  }
}

.u-mb170_half {
  margin-bottom: 85px !important
}

@media all and (min-width: 800px) {
  .u-mb170_half {
    margin-bottom: 170px !important
  }
}

.u-mb180_half {
  margin-bottom: 90px !important
}

@media all and (min-width: 800px) {
  .u-mb180_half {
    margin-bottom: 180px !important
  }
}

.u-mb15_23rd {
  margin-bottom: 10px !important
}

@media all and (min-width: 800px) {
  .u-mb15_23rd {
    margin-bottom: 15px !important
  }
}

.u-mb30_23rd {
  margin-bottom: 20px !important
}

@media all and (min-width: 800px) {
  .u-mb30_23rd {
    margin-bottom: 30px !important
  }
}

.u-mb45_23rd {
  margin-bottom: 30px !important
}

@media all and (min-width: 800px) {
  .u-mb45_23rd {
    margin-bottom: 45px !important
  }
}

.u-mb60_23rd {
  margin-bottom: 40px !important
}

@media all and (min-width: 800px) {
  .u-mb60_23rd {
    margin-bottom: 60px !important
  }
}

.u-mb75_23rd {
  margin-bottom: 50px !important
}

@media all and (min-width: 800px) {
  .u-mb75_23rd {
    margin-bottom: 75px !important
  }
}

.u-mb90_23rd {
  margin-bottom: 60px !important
}

@media all and (min-width: 800px) {
  .u-mb90_23rd {
    margin-bottom: 90px !important
  }
}

.u-mb105_23rd {
  margin-bottom: 70px !important
}

@media all and (min-width: 800px) {
  .u-mb105_23rd {
    margin-bottom: 105px !important
  }
}

.u-mb120_23rd {
  margin-bottom: 80px !important
}

@media all and (min-width: 800px) {
  .u-mb120_23rd {
    margin-bottom: 120px !important
  }
}

.u-mb135_23rd {
  margin-bottom: 90px !important
}

@media all and (min-width: 800px) {
  .u-mb135_23rd {
    margin-bottom: 135px !important
  }
}

.u-mb150_23rd {
  margin-bottom: 100px !important
}

@media all and (min-width: 800px) {
  .u-mb150_23rd {
    margin-bottom: 150px !important
  }
}

.u-mb165_23rd {
  margin-bottom: 110px !important
}

@media all and (min-width: 800px) {
  .u-mb165_23rd {
    margin-bottom: 165px !important
  }
}

.u-mb180_23rd {
  margin-bottom: 120px !important
}

@media all and (min-width: 800px) {
  .u-mb180_23rd {
    margin-bottom: 180px !important
  }
}

.u-mb195_23rd {
  margin-bottom: 130px !important
}

@media all and (min-width: 800px) {
  .u-mb195_23rd {
    margin-bottom: 195px !important
  }
}

.u-mb210_23rd {
  margin-bottom: 140px !important
}

@media all and (min-width: 800px) {
  .u-mb210_23rd {
    margin-bottom: 210px !important
  }
}

.u-mb225_23rd {
  margin-bottom: 150px !important
}

@media all and (min-width: 800px) {
  .u-mb225_23rd {
    margin-bottom: 225px !important
  }
}

.u-mb240_23rd {
  margin-bottom: 160px !important
}

@media all and (min-width: 800px) {
  .u-mb240_23rd {
    margin-bottom: 240px !important
  }
}

.u-mb255_23rd {
  margin-bottom: 170px !important
}

@media all and (min-width: 800px) {
  .u-mb255_23rd {
    margin-bottom: 255px !important
  }
}

.u-mb270_23rd {
  margin-bottom: 180px !important
}

@media all and (min-width: 800px) {
  .u-mb270_23rd {
    margin-bottom: 270px !important
  }
}

.u-mb10_level {
  margin-bottom: 3.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb10_level {
    margin-bottom: 5px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb10_level {
    margin-bottom: 10px !important
  }
}

.u-mb20_level {
  margin-bottom: 6.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb20_level {
    margin-bottom: 10px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb20_level {
    margin-bottom: 20px !important
  }
}

.u-mb30_level {
  margin-bottom: 10px !important
}

@media all and (min-width: 500px) {
  .u-mb30_level {
    margin-bottom: 15px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb30_level {
    margin-bottom: 30px !important
  }
}

.u-mb40_level {
  margin-bottom: 13.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb40_level {
    margin-bottom: 20px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb40_level {
    margin-bottom: 40px !important
  }
}

.u-mb50_level {
  margin-bottom: 16.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb50_level {
    margin-bottom: 25px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb50_level {
    margin-bottom: 50px !important
  }
}

.u-mb60_level {
  margin-bottom: 20px !important
}

@media all and (min-width: 500px) {
  .u-mb60_level {
    margin-bottom: 30px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb60_level {
    margin-bottom: 60px !important
  }
}

.u-mb70_level {
  margin-bottom: 23.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb70_level {
    margin-bottom: 35px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb70_level {
    margin-bottom: 70px !important
  }
}

.u-mb80_level {
  margin-bottom: 26.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb80_level {
    margin-bottom: 40px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb80_level {
    margin-bottom: 80px !important
  }
}

.u-mb90_level {
  margin-bottom: 30px !important
}

@media all and (min-width: 500px) {
  .u-mb90_level {
    margin-bottom: 45px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb90_level {
    margin-bottom: 90px !important
  }
}

.u-mb100_level {
  margin-bottom: 33.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb100_level {
    margin-bottom: 50px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb100_level {
    margin-bottom: 100px !important
  }
}

.u-mb110_level {
  margin-bottom: 36.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb110_level {
    margin-bottom: 55px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb110_level {
    margin-bottom: 110px !important
  }
}

.u-mb120_level {
  margin-bottom: 40px !important
}

@media all and (min-width: 500px) {
  .u-mb120_level {
    margin-bottom: 60px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb120_level {
    margin-bottom: 120px !important
  }
}

.u-mb130_level {
  margin-bottom: 43.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb130_level {
    margin-bottom: 65px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb130_level {
    margin-bottom: 130px !important
  }
}

.u-mb140_level {
  margin-bottom: 46.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb140_level {
    margin-bottom: 70px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb140_level {
    margin-bottom: 140px !important
  }
}

.u-mb150_level {
  margin-bottom: 50px !important
}

@media all and (min-width: 500px) {
  .u-mb150_level {
    margin-bottom: 75px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb150_level {
    margin-bottom: 150px !important
  }
}

.u-mb160_level {
  margin-bottom: 53.33333px !important
}

@media all and (min-width: 500px) {
  .u-mb160_level {
    margin-bottom: 80px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb160_level {
    margin-bottom: 160px !important
  }
}

.u-mb170_level {
  margin-bottom: 56.66667px !important
}

@media all and (min-width: 500px) {
  .u-mb170_level {
    margin-bottom: 85px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb170_level {
    margin-bottom: 170px !important
  }
}

.u-mb180_level {
  margin-bottom: 60px !important
}

@media all and (min-width: 500px) {
  .u-mb180_level {
    margin-bottom: 90px !important
  }
}

@media all and (min-width: 800px) {
  .u-mb180_level {
    margin-bottom: 180px !important
  }
}

.u-mb-minus10_half {
  margin-bottom: -5px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus10_half {
    margin-bottom: -10px !important
  }
}

.u-mb-minus20_half {
  margin-bottom: -10px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus20_half {
    margin-bottom: -20px !important
  }
}

.u-mb-minus30_half {
  margin-bottom: -15px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus30_half {
    margin-bottom: -30px !important
  }
}

.u-mb-minus40_half {
  margin-bottom: -20px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus40_half {
    margin-bottom: -40px !important
  }
}

.u-mb-minus50_half {
  margin-bottom: -25px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus50_half {
    margin-bottom: -50px !important
  }
}

.u-mb-minus60_half {
  margin-bottom: -30px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus60_half {
    margin-bottom: -60px !important
  }
}

.u-mb-minus70_half {
  margin-bottom: -35px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus70_half {
    margin-bottom: -70px !important
  }
}

.u-mb-minus80_half {
  margin-bottom: -40px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus80_half {
    margin-bottom: -80px !important
  }
}

.u-mb-minus90_half {
  margin-bottom: -45px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus90_half {
    margin-bottom: -90px !important
  }
}

.u-mb-minus100_half {
  margin-bottom: -50px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus100_half {
    margin-bottom: -100px !important
  }
}

.u-mb-minus110_half {
  margin-bottom: -55px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus110_half {
    margin-bottom: -110px !important
  }
}

.u-mb-minus120_half {
  margin-bottom: -60px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus120_half {
    margin-bottom: -120px !important
  }
}

.u-mb-minus130_half {
  margin-bottom: -65px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus130_half {
    margin-bottom: -130px !important
  }
}

.u-mb-minus140_half {
  margin-bottom: -70px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus140_half {
    margin-bottom: -140px !important
  }
}

.u-mb-minus150_half {
  margin-bottom: -75px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus150_half {
    margin-bottom: -150px !important
  }
}

.u-mb-minus160_half {
  margin-bottom: -80px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus160_half {
    margin-bottom: -160px !important
  }
}

.u-mb-minus170_half {
  margin-bottom: -85px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus170_half {
    margin-bottom: -170px !important
  }
}

.u-mb-minus180_half {
  margin-bottom: -90px !important
}

@media all and (min-width: 800px) {
  .u-mb-minus180_half {
    margin-bottom: -180px !important
  }
}

.u-mb40_0 {
  margin-bottom: 0 !important
}

@media all and (min-width: 800px) {
  .u-mb40_0 {
    margin-bottom: 40px !important
  }
}

.u-mb40_10 {
  margin-bottom: 10px !important
}

@media all and (min-width: 800px) {
  .u-mb40_10 {
    margin-bottom: 40px !important
  }
}

.u-mb70_20 {
  margin-bottom: 20px !important
}

@media all and (min-width: 800px) {
  .u-mb70_20 {
    margin-bottom: 70px !important
  }
}

.u-mb80_0 {
  margin-bottom: 0 !important
}

@media all and (min-width: 800px) {
  .u-mb80_0 {
    margin-bottom: 80px !important
  }
}

.u-mb80_30 {
  margin-bottom: 30px !important
}

@media all and (min-width: 800px) {
  .u-mb80_30 {
    margin-bottom: 80px !important
  }
}

.u-mb110_30 {
  margin-bottom: 30px !important
}

@media all and (min-width: 800px) {
  .u-mb110_30 {
    margin-bottom: 110px !important
  }
}

.js-fadeIn {
  opacity: 0.1;
  -webkit-transform: translate(0, 30px);
  transform: translate(0, 30px);
  transition: all 1000ms
}

.js-fadeIn.js-scrollIn {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0)
}

@-webkit-keyframes drawerIn {
  0% {
    -webkit-transform: translate(-50%, -100vh);
    transform: translate(-50%, -100vh);
    background: rgba(13, 111, 184, 0)
  }
  1% {
    background: rgba(13, 111, 184, 0.95)
  }
  100% {
    background: rgba(13, 111, 184, 0.95);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }
}

@keyframes drawerIn {
  0% {
    -webkit-transform: translate(-50%, -100vh);
    transform: translate(-50%, -100vh);
    background: rgba(13, 111, 184, 0)
  }
  1% {
    background: rgba(13, 111, 184, 0.95)
  }
  100% {
    background: rgba(13, 111, 184, 0.95);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }
}

@-webkit-keyframes drawerOut {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background: rgba(13, 111, 184, 0.95)
  }
  99% {
    background: rgba(13, 111, 184, 0.95)
  }
  100% {
    background: rgba(13, 111, 184, 0);
    -webkit-transform: translate(-50%, -100vh);
    transform: translate(-50%, -100vh)
  }
}

@keyframes drawerOut {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background: rgba(13, 111, 184, 0.95)
  }
  99% {
    background: rgba(13, 111, 184, 0.95)
  }
  100% {
    background: rgba(13, 111, 184, 0);
    -webkit-transform: translate(-50%, -100vh);
    transform: translate(-50%, -100vh)
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@-webkit-keyframes zIndexPlus {
  0% {
    z-index: 999
  }
  99% {
    z-index: 999
  }
  100% {
    z-index: 1001
  }
}

@keyframes zIndexPlus {
  0% {
    z-index: 999
  }
  99% {
    z-index: 999
  }
  100% {
    z-index: 1001
  }
}

@-webkit-keyframes zIndexMinus {
  0% {
    z-index: 1001
  }
  1% {
    z-index: 999
  }
  100% {
    z-index: 999
  }
}

@keyframes zIndexMinus {
  0% {
    z-index: 1001
  }
  1% {
    z-index: 999
  }
  100% {
    z-index: 999
  }
}
